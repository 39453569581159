<template>
  <div>
    <input
      autocomplete="off"
      :value="props.filter.value"
      :class="['form-control']"
      @change="onChange"
    />
  </div>
</template>

<script setup lang="ts">
  import type { ColumnFilterProps, ColumnFilterEmits } from "../../lib/interfaces";

  const props = defineProps<ColumnFilterProps>();
  const emit = defineEmits<ColumnFilterEmits>();

  const onChange = (evt: Event) => {
    const input: HTMLInputElement = evt.target;

    emit("update:filter", {
      filterName: props.column.name,
      data: input.value,
    });
  };
</script>

<style scoped></style>
